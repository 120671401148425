
import types from './types';

export default {
	[types.SET_MOBILE_MENU_VISIBLE](state, flag)
	{
		state.showMobileMenu = flag;
	},

	[types.SET_CURRENT_USER](state, data)
	{
		state.currentUser = { ...data };
	},
	[types.SET_PRODUCT_LOYALTY_CODE](state, data)
	{
		state.productLoyaltyCode = data ;
	},
	[types.SET_ROOM_OPTIONS](state, data)
	{
		var roomOptions = {};
		var optionMap = {};

		/*
		process incoming room option data so that
		each field contains an array, each element of those array is an array of IDs into option map
		option map is an ID => option data lookup table
		*/
		for(var field in data)
		{
			// initialize field info
			roomOptions[field] = [];

			// go through each option list and replace it with a list of ids, 
			// mapping each option to those ids
			for(var i=0; i<data[field].length; i++)
			{
				roomOptions[field][i] = data[field][i].map(opt => {
					//opt.option_name = _.capitalize(opt.option_name);
					optionMap[opt.id] = opt;
					return opt.id;
				});
			}
		}

		state.roomOptions = roomOptions;
		state.optionMap = optionMap;
	},

	[types.SET_DASHBOARD_COLLAPSE](state, flag)
	{
		state.collapsed = flag;
	},

	// store franchise options so that other dashboard modules can access franchise data without an extra AJAX request
	[types.SET_DASHBOARD_FRANCHISES](state, list)
	{
		var franchises = {}, byTerritory = {};

		if (list && list !== null) {
			state.franchiseIds = list.map(f => {
				// store franchises in lookup table
				franchises[f.id] = { ...f };
	
				// create a mapping between SF territory ID strings and franchise IDs in the OBE database
				byTerritory[f.sf_territory_id] = f.id;
	
				return f.id;
			});
		} else {
			state.franchiseIds = [];
		}

		state.franchises = franchises;
		state.franchisesByTerritory = byTerritory;
	},

	[types.SET_DASHBOARD_UPCOMING_ORDERS](state, num)
	{
		state.upcomingOrders = num;
	},

}